import React from "react"
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share"
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share"

const SocialShareComponent = ({ openShareicons, shareurl }) => {
  const trackerShare = event => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "Share - social icons",
      formType: event + " - " + shareurl,
      formId: "Share",
      formName: "Share",
      formLabel: "Share",
    })
  }
  return (
    <div onClick={openShareicons} className="property-share-social-icons">
      <FacebookShareButton
        onClick={() => trackerShare("FacebookShareButton")}
        url={shareurl}
        className="my-share-button facebook-share"
      >
        <FacebookIcon size={32} round={false} borderRadius={`10`} />
      </FacebookShareButton>
      <TwitterShareButton
        onClick={() => trackerShare("TwitterShareButton")}
        url={shareurl}
        className="my-share-button twitter-share"
      >
        <TwitterIcon size={32} round={false} borderRadius={`10`} />
      </TwitterShareButton>
      <LinkedinShareButton
        onClick={() => trackerShare("LinkedinShareButton")}
        url={shareurl}
        className="my-share-button linkedin-share"
      >
        <LinkedinIcon size={32} round={false} borderRadius={`10`} />
      </LinkedinShareButton>
      <WhatsappShareButton
        onClick={() => trackerShare("WhatsappShareButton")}
        url={shareurl}
        className="my-share-button whatsapp-share"
      >
        <WhatsappIcon size={32} round={false} borderRadius={`10`} />
      </WhatsappShareButton>
    </div>
  )
}

export default SocialShareComponent
