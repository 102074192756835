import { Link } from "gatsby"
import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Container } from "react-bootstrap"
import "./GuidesPageModule.scss"
import GGFXImage from "../../modules/GGFXImage"
const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/Modules/ImageModule")

const GuidesPageModule = ({ data, PageData }) => {
  const imagename = "page.image_listing_image.card_module"
  return (
    <>
      {data?.image_module?.length > 0 && (
        <div className="landing-card-wrapper">
          <Container>
            <div className="card-item-sections">
              {data?.image_module.map((item, index) => {
                const linkParent = item?.link?.strapi_parent?.slug ? item?.link?.strapi_parent?.slug :
                item?.link?.parent?.slug

                let processedImages = JSON.stringify({})
                if (PageData?.imagetransforms?.image_listing_image_Transforms) {
                  processedImages =
                    PageData?.imagetransforms?.image_listing_image_Transforms
                }

                let url = ""
                if (linkParent) {
                  url = `/${linkParent}/${item.link?.slug}`
                } else if (item.link?.slug) {
                  url = `${item.link?.slug}/`
                }
                else if(item?.custom_link){
                  url = `${item.custom_link}`
                }

                return (
                  <ScrollAnimation
                    animateIn="animate__slideInUp"
                    animateOnce
                    delay={index * 100}
                    offset={100}
                  >
                    <div className="card-item" key={index}>
                      <Link to={url + "/"}>
                        <div className="image-section">
                          <GGFXImage
                            ImageSrc={item?.image}
                            altText={`${item?.title}`}
                            imagetransforms={PageData?.ggfx_results}
                            renderer="srcSet"
                            imagename={imagename}
                            strapiID={PageData?.strapi_id}
                          />
                          {/* <img src={item.image?.url} alt="card-img" /> */}
                          <div className="image-overlay">
                            <span>
                              <i className="icon white-up-arrow" />
                            </span>
                          </div>
                        </div>
                      </Link>
                      <div className="card-item-content">
                        <Link to={url + "/"}>
                          <h3>{item?.title}</h3>
                        </Link>
                      </div>
                    </div>
                  </ScrollAnimation>
                )
              })}
            </div>
          </Container>
        </div>
      )}
    </>
  )
}

export default GuidesPageModule
