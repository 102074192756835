import { graphql } from "gatsby"
import React, {useState, useEffect} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentComponent from "../components/ContentComponent/ContentComponent"
import GuidesPageModule from "../components/GuidesPageModule/GuidesPageModule"
import LandingStaticCard from "../components/LandingStaticCard/LandingStaticCard"
import BreadcrumbModule from "../components/BreadcrumbModule/BreadcrumbModule"
import { Container } from "react-bootstrap"
import SocialShareComponent from "../components/SocialShareComponent/SocialShareComponent"
import { formTracking ,removeDataAttributes} from "../components/Common/utils"
import { Helmet } from "react-helmet"
import logoBlack from "../images/logo-white.svg"
import qs from "qs"


const PrimaryLandingTemplate = ({ data,pageContext }) => {
  // const PageData = data?.strapiPage
  const [PageData, setPageData] = useState(data?.strapiPage)
  const [loading,setLoading] = useState(true)
  const [isPreviewEnabled, setIsPreviewEnabled]=useState(false)
  const [shareIcons, setShareIcons] = useState(false)

  const [enablePreview, setEnablePreview] = useState(pageContext?.strapi_preview == "true" ? true : false) // since its string

  const pageurl = typeof window !== "undefined" ? window.location.href : ""

  const openShareicons = () => {
    setShareIcons(true)
    if (shareIcons === true) {
      setShareIcons(false)
    }
    formTracking({
      event_tracking: "share-property",
      form_name: "Share Property ",
    })
  }

  let popUpData=""

  if(PageData?.add_page_modules?.length>0){
     const results=PageData?.add_page_modules?.filter(item=>item.__typename==="STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT")
     if(results?.length>0){
      popUpData=results[0]
     }
  }

  var ldJson = {
    "@context": "http://schema.org",
    "@type": "RealEstateAgent",
    Name: "haus & haus",
    URL: pageurl,
    Image: "https://ggfx-handh3.s3.eu-west-2.amazonaws.com/i.prod/haus_homepage_contact_headoffice_e092721ac2_9585806d12.png",
    Logo: "https://ggfx-handh3.s3.eu-west-2.amazonaws.com/i.prod/logo_white_schema_9fd27d4e15.png"
  }


  const query = qs.stringify(
    {
      populate: {
        choose_menu: "*",
        seo:"*",
        select_popular_search:"*",
        add_page_modules: {
          on: {
            "page-modules.image-listing": {
              populate:{
                image_module:{
                  populate:{
                    link:{
                      populate:{
                        parent:"*"
                      }
                    },
                    image:"*"
                  }
                }
              }
            },
            "components.title-desc":{
              populate:"*"
            },
            "page-modules.static-card-items":{
                  populate:{
                    cards:{
                      populate:{
                        cta:{
                          populate:{
                            link: {
                              populate:{
                                parent:"*"
                              }
                            },
                            image:"*"
                          }
                        }
                      }
                 }

              }
            }
          }
        },
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );

  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const isPreview = urlParams.get('debug') === 'preview';
    const apiUrl=`${process.env.GATSBY_STRAPI_SRC}/api/pages/${urlParams.get('strapi_id')}?${query}`

    const bearerToken = process.env.GATSBY_STRAPI_API_TOKEN;

    if(isPreview){
      setIsPreviewEnabled(true)
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl, {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        });
        const getData = await response.json();
        const previewData=removeDataAttributes(getData)
        setLoading(false)
        setEnablePreview(false)
        let myPrevData;
        if(PageData){
          myPrevData = PageData
        } else {
          myPrevData = {};
          myPrevData.add_page_modules=[]
          myPrevData.id = urlParams.get('strapi_id')
        }
        if(previewData){
          myPrevData.title = previewData?.title
          myPrevData.add_page_modules=previewData?.add_page_modules
          myPrevData.layout = previewData?.layout
          myPrevData.select_popular_search = previewData?.select_popular_search
          setPageData(PageData => ({ ...PageData, myPrevData }));
          sessionStorage.setItem("previewMeta",JSON.stringify(previewData?.seo))
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }

    };
    fetchData();
    }
    else{
      setLoading(false)
      setIsPreviewEnabled(false)
      sessionStorage.removeItem("previewMeta")
    }

  },[])

  let urlParamsVal = ""
  let isPreviewVal = false

  if(typeof window !== "undefined"){
     urlParamsVal = new URLSearchParams(window.location.search);
    isPreviewVal = urlParamsVal.get('debug') === 'preview';
  }

  if(isPreviewVal){
    if(loading){
      return (
        <section className={"loader-wrapper"}>
          <div id="loader-wrapper">
            <div id="loader" class="new-loader">
              <img
                className="logo-white loader-logo"
                src={logoBlack}
                alt="logo"
              />
            </div>
          </div>
        </section>
      )
    }
  }
  if(enablePreview){
    return null
  }


  return (
    <Layout popUpData={popUpData} dark={true} popularSearch={PageData?.select_popular_search?.title}>
      {PageData?.title==="About"&&
      <Helmet>
        <script type="application/ld+json">
            {JSON.stringify(ldJson, null, 2)}
        </script>
      </Helmet>
    }
      <div className="layout-padding-top"></div>
      
      <Container>
      <div className="breadcum-share-wrapper">
       <BreadcrumbModule
          choose_menu={PageData?.choose_menu}
          pagename={PageData?.title}
          type="share-button"
        />

          <div className="share-news-details" onClick={() => openShareicons()}>
            <i className="icon grey-share" />
            <span>Share</span>
            {shareIcons && (
              <SocialShareComponent
                openShareicons={openShareicons}
                shareurl={pageurl}
              />
            )}
          </div>

      </div>
      </Container>
      {PageData?.add_page_modules?.map((item, i) => {
        return (
          <div>
            {(item?.__typename ===
              "STRAPI__COMPONENT_COMPONENTS_TITLE_DESC" ||
              item?.__component === "components.title-desc")
               && (
              <ContentComponent isPreviewEnabled={isPreviewEnabled} data={item} />
            )}
            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_IMAGE_LISTING" ||
              item?.__component === "page-modules.image-listing")
              && (
              <GuidesPageModule data={item} PageData={PageData} />
            )}
          {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_STATIC_CARD_ITEMS" || 
              item?.__component === "page-modules.static-card-items")
              && (
              <LandingStaticCard isPreviewEnabled={isPreviewEnabled} data={item} />
            )}
          </div>
        )
      })}
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage

   //preview seo dynamic updates

   let isPreview=""
   if(typeof window!=="undefined"){
   const urlParams = new URLSearchParams(window.location.search);
    isPreview = urlParams.get('debug') === 'preview';
   }
 
   const metaTitle = PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title
 
   const [preMetaTitle, setPreMetaTitle] = useState(metaTitle)
 
 
   useEffect(() => {
     const timer = setTimeout(() => {
       if (typeof window !== "undefined" && isPreview) {
         try {
           const sessionMetaData = sessionStorage.getItem("previewMeta");
           if (sessionMetaData) {
             const parsedData = JSON.parse(sessionMetaData);
           if(parsedData?.metaTitle){
             setPreMetaTitle(parsedData?.metaTitle)
           }
         }
       } catch (error) {
         console.error("Error parsing session storage data:", error);
       }
     }
   }, 4000);
 
   // Clear the timeout if the component unmounts
   return () => clearTimeout(timer);
 }, [isPreview]);
 
 useEffect(() => {
   if(isPreview){
   document.title =  `${preMetaTitle} haus & haus`;
   }
 }, [preMetaTitle]);
 //

  return (
      <SEO title={(isPreview && preMetaTitle) ? preMetaTitle : metaTitle} 
      description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} 
      imageUrl="https://ggfx-handh3.s3.eu-west-2.amazonaws.com/i/file_7035ecdafe.jpg?updated_at=2024-07-08T11:28:34.283Z"

      />
  )
}

export default PrimaryLandingTemplate

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment

      add_page_modules {
        ... on STRAPI__COMPONENT_COMPONENTS_TITLE_DESC {
          __typename
          id
          title
          description {
            data {
              description
            }
          }
        }

        ... on STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT {
          __typename
          ...GlobalPopUpFragment
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_LISTING {
          __typename
          id
          image_module {
            title
            image {
              url
            }
            link{
              slug
              strapi_parent {
                slug
              }
            }
            link_type
            custom_link
          }
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_STATIC_CARD_ITEMS {
          __typename
          id
          cards {
            title
            content {
              data {
                content
              }
            }
            cta {
              link {
                slug
                strapi_parent {
                  slug
                }
              }
              title
              custom_link
            }
          }
        }
      }
    }
  }
`
